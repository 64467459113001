@import '../../../../scss/theme-bootstrap';

.sticky-footer-live-chat {
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-ly-dark-green;
    pointer-events: auto;
    position: fixed;
    text-align: center;
    z-index: 100;
    padding: 10px 15px;
    #{$ldirection}: auto;
    #{$rdirection}: 0;
    @if $chat-icon-position-right {
      @media #{$cr19-large-up} {
        #{$rdirection}: auto;
      }
    }
    width: auto;
    height: 50px;
    bottom: 0;
    &:hover {
      background-color: $color-ly-dark-green;
    }
    @media #{$cr19-large-up} {
      padding: 10px 27px;
    }
    .page-product & {
      bottom: 80px;
      @media #{$cr19-large-up} {
        bottom: 84px;
      }
    }
    &-title {
      flex: 0 0 auto;
      color: $color-black;
      text-transform: uppercase;
      font-family: $base-bold-font-family;
      font-size: 12px;
      margin-#{$ldirection}: 12px;
      font-weight: bold;
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
  }
  .icon--chat {
    fill: $color-black;
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
  }
}
